import { v4 as uuidv4 } from 'uuid';

export const getParticipantsData = async (client, id) => {
    const data = await client.get('tourism-order/b2b-participants/list/' + id);
    return data;
};

export const getColumnPropertyFromConfig = (
    key,
    columnsProperties,
    property
) => {
    return columnsProperties.find((col) => col.key === key)[property];
};

export const getDefaultColumnConfig = (columnsProperties) => {
    return columnsProperties.find((col) => col.key === 'notFound');
};

export const getColumnProperties = (key, columnsProperties) => {
    return columnsProperties.find((col) => col.key === key);
};

export const removeOneRow = (id, setFormData) => {
    setFormData((formData) => {
        return formData.filter((row) => row?.id !== id);
    });
};

export const updateFieldValue = (
    newValue,
    rowId,
    ColumnKey,
    setFormData,
    dynamicItemId = '',
    subKey = ''
) => {
    setFormData((formData) => {
        return formData.map((row) => {
            if (dynamicItemId) {
                return row?.id === rowId
                    ? !subKey
                        ? {
                              ...row,
                              [ColumnKey]: {
                                  ...row[ColumnKey],
                                  [dynamicItemId]: newValue,
                              },
                          }
                        : {
                              ...row,
                              [subKey]: {
                                  ...row[subKey],
                                  [dynamicItemId]: newValue,
                              },
                          }
                    : row;
            }
            return row?.id === rowId ? { ...row, [ColumnKey]: newValue } : row;
        });
    });
};

const partdateVal = (value) => {
    if (value) {
        value = value.toString().slice(0, 10);
        return value;
    }
};
export const inputValueParser = (value, subType, key, nestedSubtype = '') => {
    if (subType == 'nested') {
        if (value) {
            const fieldVal = value[key];
            if (nestedSubtype == 'date') {
                return !fieldVal ? '' : partdateVal(fieldVal);
            }
            return fieldVal;
        }
    }

    if (subType == 'date') {
        const val = partdateVal(value);
        return val;
    }

    if (typeof value == 'object') {
        const val = '';
        return val;
    }

    return value;
};

export const postToServer = async (data, client) => {
    return await client.request(
        'post',
        'tourism-order/update-b2b-participant-list',
        data,
        {},
        { headers: {} }
    );
};

export const makeFormDataFromDefaultData = (defaultData, activeColumns) => {
    const { participants } = defaultData;
    return participants.map((participant) => {
        const row = { id: participant.id };

        if (activeColumns.length > 0) {
            activeColumns.forEach((column) => {
                if (participant.hasOwnProperty(column.key)) {
                    row[column.key] = participant[column.key];
                    if (column.key === 'b2bGroup' && row[column.key]) {
                        row[column.key] = row[column.key]?.id;
                    }
                } else {
                    row[column.key] = '';
                }
            });
        }
        row['orderItems'] = [];
        row['combinedOrderItems'] = {};
        if (participant?.orderItems?.length > 0) {
            const combinedOrderItemsColumnsValues = activeColumns
                .filter((col) => col.key == 'combinedOrderItems')
                ?.flatMap((c) =>
                    c.choices?.map((choice) => ({
                        id: c.dynamicItemId,
                        value: choice.value,
                    }))
                );

            participant?.orderItems.forEach((item) => {
                const inCombined = combinedOrderItemsColumnsValues.find(
                    (col) => col.value === item.id
                );

                if (inCombined) {
                    row['combinedOrderItems'] = {
                        ...row['combinedOrderItems'],
                        [inCombined.id]: item.id,
                    };
                } else {
                    row['orderItems'] =
                        row['orderItems']?.length > 0
                            ? [...row['orderItems'], { id: item.id }]
                            : [{ id: item.id }];
                }
            });
        }
        row['showRoomNumberField'] = {};

        if (defaultData?.roomSelectionsByParticipant?.length > 0) {
            const participantData = defaultData?.roomSelectionsByParticipant.find(
                (i) => i.participant === participant.id
            );

            if (participantData) {
                const b2bRooms = participantData.b2bParticipantRoomSelection;
                const combinedOrders = activeColumns.filter(
                    (i) => i.key === 'combinedOrderItems'
                );
                if (combinedOrders?.length > 0 && b2bRooms.length > 0) {
                    for (
                        let index = 0;
                        index < combinedOrders.length;
                        index++
                    ) {
                        b2bRooms.forEach((room) => {
                            let combineColumn = '';
                            const element = combinedOrders[index];
                            const choiceExist = element.choices.find(
                                (i) => i.value === room?.orderItem
                            );
                            if (choiceExist) {
                                combineColumn = element?.dynamicItemId?.trim();
                                row['showRoomNumberField'] = {
                                    ...row['showRoomNumberField'],
                                    [combineColumn]: room.roomIndex,
                                };
                            }
                        });
                    }
                }
            }
        }

        return row;
    });
};
export const cancelledOrderItemsData = (defaultData) => {
    if (defaultData.cancellationHandlingConfig.length <= 1) {
        return [];
    }
    const data = defaultData.cancellationHandlingConfig
        .map((item) => {
            if (item.grantCancellationDiscount) {
                return item.orderItem;
            }
        })
        .filter((item) => item);
    return data;
};
export const shouldFormShowField = (key, data, columnsConfig, column) => {
    const showIf = getColumnPropertyFromConfig(key, columnsConfig, 'showIf');

    return showIf?.length > 0
        ? showIf.some((cond) => {
              const { condition, value, field } = cond;
              switch (condition) {
                  case 'and':
                      return showIf.every((andCond) => {
                          if (field) {
                              return field == value;
                          }
                          return data[andCond.key] === andCond.value;
                      });
                  case 'or':
                      return showIf.some((orCond) => {
                          if (field) {
                              return field == value;
                          }
                          return data[orCond.key] === orCond.value;
                      });
                  default:
                      return true;
              }
          })
        : true;
};

export const createHeaders = (columns, columnsConfig, ref = undefined) => {
    const columnData = columns
        .map(
            ({
                key,
                label,
                dynamicItemId,
                position,
                choices,
                driverIsParticipant,
                showRoomNumberField,
                itemIds,
            }) => {
                return {
                    key: key,
                    sort:
                        (position || 0) +
                            getColumnPropertyFromConfig(
                                key,
                                columnsConfig,
                                'sortOrder'
                            ) || Infinity,
                    label: label,
                    width:
                        getColumnPropertyFromConfig(
                            key,
                            columnsConfig,
                            'width'
                        ) || getDefaultColumnConfig().width,
                    minWidth:
                        getColumnPropertyFromConfig(
                            key,
                            columnsConfig,
                            'minWidth'
                        ) || getDefaultColumnConfig().minWidth,
                    dynamicItemId: dynamicItemId || '',
                    required:
                        getColumnPropertyFromConfig(
                            key,
                            columnsConfig,
                            'required'
                        ) || false,
                    sticky:
                        getColumnPropertyFromConfig(
                            key,
                            columnsConfig,
                            'sticky'
                        ) || false,
                    defaultValue:
                        getColumnPropertyFromConfig(
                            key,
                            columnsConfig,
                            'defaultValue'
                        ) || undefined,
                    hideFooterCount:
                        getColumnPropertyFromConfig(
                            key,
                            columnsConfig,
                            'hideFooterCount'
                        ) || false,
                    driverIsParticipant: driverIsParticipant || undefined,
                    showRoomNumberField: showRoomNumberField,
                    choices: choices,
                    itemIds: itemIds || undefined,
                    fieldType:
                        getColumnPropertyFromConfig(
                            key,
                            columnsConfig,
                            'type'
                        ) || undefined,
                    fieldSubType:
                        getColumnPropertyFromConfig(
                            key,
                            columnsConfig,
                            'subType'
                        ) || undefined,
                    ref: ref ? ref() : '',
                };
            }
        )
        .sort((a, b) => a.sort - b.sort);

    return columnData;
};

export const makeRequestData = (
    formValues,
    data,
    tableColumns,
    cancelledItems
) => {
    console.log(tableColumns, 'tableColumns');
    let postableData = [];
    let notPostableData = [];
    let haveErrors = [];
    let rows = formValues.forEach((participant) => {
        if (!participant?.firstName || !participant?.lastName) {
            notPostableData.push(participant);
            haveErrors.push({
                ...participant,
                error: `Typ fehlt for  ${participant.firstName} ${participant.lastName}`,
            });
        } else {
            postableData.push(participant);
        }
    });
    postableData = postableData.map((participant) => {
        let newParticipant = {
            ...participant,
        };
        if (newParticipant?.isNew) {
            newParticipant = {
                ...newParticipant,
                id: null,
            };
            delete newParticipant?.isNew;
        }
        if (newParticipant?.passport?.type) {
            newParticipant = {
                ...newParticipant,
                passportType: participant.passport.type,
                passportNumber: participant.passport.passportNumber,
                dateOfIssue: participant.passport.dateOfIssue,
                placeOfIssue: participant.passport.placeOfIssue,
                countryOfIssue: participant.passport.countryOfIssue,
                dateOfExpiry: participant.passport.dateOfExpiry,
            };
        }
        if (newParticipant.orderItems?.length > 0) {
            const orderItemdata = newParticipant.orderItems
                .map((item) => {
                    // if (participant.type == 'driver') {
                    //     const dynamicColumn = tableColumns.find(
                    //         (col) =>
                    //             col?.dynamicItemId == item.id &&
                    //             col.key == 'orderItems'
                    //     );

                    //     if (
                    //         !dynamicColumn?.driverIsParticipant.toString() ===
                    //         'false'
                    //     ) {
                    //         return undefined;
                    //     }
                    // }
                    return item.id;
                })
                .filter((emp) => emp);
            let combined = [];
            if (
                newParticipant?.combinedOrderItems &&
                Object.values(newParticipant?.combinedOrderItems)?.length > 0
            ) {
                combined = Object.values(newParticipant?.combinedOrderItems)
                    .map((item) => {
                        return item;
                    })
                    .filter((emp) => emp);
            }
            newParticipant.orderItems = orderItemdata.concat(combined);
        }
        // roomSelection
        if (newParticipant?.showRoomNumberField) {
            let roomSelections = [];
            for (const [key, value] of Object.entries(
                newParticipant?.showRoomNumberField
            )) {
                const id = newParticipant?.combinedOrderItems
                    ? newParticipant?.combinedOrderItems[key]
                    : '';
                if (id) {
                    roomSelections.push({ orderItem: id, roomIndex: value });
                }
            }
            newParticipant.roomSelections = roomSelections;
        } else {
            newParticipant.roomSelections = [];
        }

        delete newParticipant?.passport;
        delete newParticipant?.action;
        delete newParticipant?.combinedOrderItems;
        delete newParticipant?.showRoomNumberField;
        // if (
        //     newParticipant.b2bGroup == '6409c3924840594d200c4313' &&
        //     newParticipant.orderItems.includes('6447a2f957277ac3ea02e09a')
        // ) {
        //     console.log('in');
        // }

        return newParticipant;
    });

    if (data?.participants.length > 0) {
        data?.participants.forEach((orderParticipant) => {
            const id = orderParticipant.id;
            const isExist = formValues.some(
                (participant) => participant.id === id
            );
            if (!isExist) {
                postableData.push({ id: id, isDeleted: true });
            }
        });
    }
    return [
        {
            order: data.order.id,
            rows: postableData,
            cancellationConfiguration: [...cancelledItems],
        },
        notPostableData,
        haveErrors,
    ];
};

const makeFormRow = (columns) => {
    const newRow = { id: uuidv4(), isNew: true };
    columns.forEach(
        ({ key, defaultValue, dynamicItemId, showRoomNumberField }) => {
            newRow[key] = defaultValue;
            if ((key = 'combinedOrderItems')) {
                if (dynamicItemId && showRoomNumberField) {
                    newRow['showRoomNumberField'] = {
                        ...newRow['showRoomNumberField'],
                        [dynamicItemId?.trim()]: 1,
                    };
                }
            }
        }
    );
    return newRow;
};

export const addNewFormRow = (setFormData, columns) => {
    setFormData((formData) => {
        return formData?.length > 0
            ? [...formData, makeFormRow(columns)]
            : [makeFormRow(columns)];
    });
};

export const makeNewFormEmptyRows = (columns, columnsConfig, roomConfig) => {
    const headers = createHeaders(columns, columnsConfig);

    let participants = [];

    roomConfig.forEach((config) => {
        for (let i = 1; i <= config?.roomAmount; i++) {
            for (let j = 1; j <= config?.rowsPerRoom; j++) {
                const newRow = { id: uuidv4(), isNew: true };
                headers.forEach(
                    ({
                        key,
                        defaultValue,
                        dynamicItemId,
                        showRoomNumberField,
                    }) => {
                        newRow[key] = newRow[key] || defaultValue;
                        if (key == 'b2bGroup') {
                            newRow[key] = config.groupId;
                        }
                        if (key == 'roomIndex') {
                            newRow[key] = i;
                        }
                        if (key == 'type') {
                            newRow[key] = 'participant';
                        }
                        if (key == 'salutation') {
                            newRow[key] = j == 1 ? 'male' : 'female';
                        }
                        if (key == 'nationality') {
                            newRow[key] = 'DE';
                        }
                        if (key == 'birthdate') {
                            newRow[key] = '';
                        }

                        if (key == 'orderItems') {
                            const associatedItems =
                                config?.associatedOrderItems?.length > 0 &&
                                config?.associatedOrderItems.map((item) => ({
                                    id: item,
                                }));

                            if (newRow[key]?.length > 0) {
                                newRow[key] = newRow[key];
                            } else {
                                newRow[key] = associatedItems;
                            }

                            const isServiceExist =
                                config?.associatedOrderItems?.length > 0 &&
                                roomConfig?.some((item) =>
                                    item.associatedOrderItems?.includes(
                                        dynamicItemId
                                    )
                                );

                            if (!isServiceExist) {
                                newRow[key] =
                                    newRow[key]?.length > 0
                                        ? [
                                              ...newRow[key],
                                              {
                                                  id: dynamicItemId,
                                              },
                                          ]
                                        : [
                                              {
                                                  id: dynamicItemId,
                                              },
                                          ];
                            }
                        }
                        if ((key = 'passport')) {
                            newRow[key] = {};
                        }
                        if ((key = 'combinedOrderItems')) {
                            if (dynamicItemId && showRoomNumberField) {
                                newRow['showRoomNumberField'] = {
                                    ...newRow['showRoomNumberField'],
                                    [dynamicItemId?.trim()]: i,
                                };
                            }
                        }
                    }
                );

                participants.push(newRow);
            }
        }
    });

    return participants;
};

const findLongestCommonSubstring = (strings) => {
    const numStrings = strings.length;
    if (numStrings === 0) {
        return '';
    }

    const minLength = Math.min(...strings.map((str) => str.length));

    let commonPrefix = '';
    for (let i = 0; i < minLength; i++) {
        const char = strings[0][i];
        if (strings.every((str) => str[i] === char)) {
            commonPrefix += char;
        } else {
            break;
        }
    }

    return commonPrefix;
};

export const makeColumnFromOrderAndCombinedItems = ({
    orderItemHeads,
    combinedOrderItemHeads,
}) => {
    const orderItemsList = orderItemHeads?.length
        ? orderItemHeads.map((item) => {
              return {
                  key: 'orderItems',
                  label: item.title,
                  dynamicItemId: item.itemId,
                  position: item?.position,
                  driverIsParticipant: item?.driverIsParticipant,
              };
          })
        : [];

    const choiceValueFilterer = (val, allValues, title) => {
        if (val === undefined || allValues === undefined) {
            console.error('Invalid input: val or allValues is undefined.');
            return '';
        }
        if (allValues.length === 0) {
            console.warn('Warning: allValues is an empty array.');
            return val;
        }

        const choicesArray =
            allValues.length > 0 ? allValues.map((v) => v.title) : [];

        const commaIndex = title.indexOf(',');
        title =
            commaIndex === -1
                ? title
                : title.substring(commaIndex + 1).trim() + ', ';

        const commonSubstring =
            choicesArray.length > 0
                ? findLongestCommonSubstring([...choicesArray, title])
                : '';
        const modifiedVal = val.replace(commonSubstring, '');

        return modifiedVal;
    };
    const combinedOrderItemsList = combinedOrderItemHeads?.length
        ? combinedOrderItemHeads.map((item) => {
              const choices = item?.values?.length
                  ? item?.values?.map((choice) => {
                        return {
                            label: choiceValueFilterer(
                                choice?.title || '',
                                item?.values,
                                item?.title
                            ),
                            value: choice?.itemId,
                        };
                    })
                  : [];
              return {
                  key: 'combinedOrderItems',
                  label: item.title,
                  dynamicItemId: item?.title,
                  position: item?.position,
                  choices: choices,
                  driverIsParticipant: item?.driverIsParticipant,
                  showRoomNumberField: item?.showRoomNumberField,
                  itemIds: item?.itemIds,
              };
          })
        : [];
    const combined = [].concat(orderItemsList, combinedOrderItemsList);
    return combined;
};

export const makeColumnFromPassportHeads = (
    passportHeads,
    columnPropertiesData,
    i18n
) => {
    const passportFields = columnPropertiesData.find((f) => f.key == 'passport')
        .subFields;

    return passportHeads.map((item) => {
        return {
            key: 'passport',
            label:
                passportFields?.find((f) => f.key == item.key)?.label ||
                il8n.tr(item.translation),
            dynamicItemId: item.key,
            dynamicType: item?.type,
            dynamicChoiceSet: item?.set,
        };
    });
};
export const makeColumnFromDefaultHeads = (heads, excludeList, i18n) => {
    return heads
        .map((item) => {
            return excludeList.includes(item.key)
                ? undefined
                : {
                      key: item.key,
                      label: i18n.tr(item.translation),
                  };
        })
        .filter((i) => !!i);
};

export const makeHeadersFromDefaultData = (
    defaultData,
    tableColumns,
    services,
    columnPropertiesData,
    order
) => {
    const makeSimpleHeads = defaultData?.head.length
        ? makeColumnFromDefaultHeads(
              defaultData?.head,
              ['passport'],
              services.i18n
          )
        : [];

    let tableColumnHeaders = [...makeSimpleHeads, ...tableColumns];

    if (defaultData?.orderItemHeads?.length > 0) {
        tableColumnHeaders = [
            ...tableColumnHeaders,
            ...makeColumnFromOrderAndCombinedItems(defaultData),
        ];
    }
    if (defaultData?.passportHeads?.length > 0 && order?.passportsRequired) {
        tableColumnHeaders = [
            ...tableColumnHeaders,
            ...makeColumnFromPassportHeads(
                defaultData?.passportHeads,
                columnPropertiesData,
                services.i18n
            ),
        ];
    }

    return tableColumnHeaders;
};

export const summaryCounter = (
    formData,
    key,
    type,
    subType,
    dynamicItemId,
    subFields
) => {
    if ((type == 'input' || type == 'choice') && !dynamicItemId) {
        const sum = formData?.reduce((total, current) => {
            return current[key] ? total + 1 : total;
        }, 0);
        return sum;
    }
    if (type == 'input' && dynamicItemId) {
        if (key == 'combinedOrderItems') {
            const sum = formData?.reduce((total, current) => {
                return current[key] && current[key][dynamicItemId]
                    ? total + 1
                    : total;
            }, 0);
            return sum;
        }
        const sum = formData?.reduce((total, current) => {
            return current[key]?.find((id) => id.id === dynamicItemId)
                ? total + 1
                : total;
        }, 0);
        return sum;
    }

    if (type == 'nested' && dynamicItemId) {
        const subFieldData = subFields.find(
            (item) => item.key == dynamicItemId
        );
        const sum = formData.reduce((total, current) => {
            return current[key] && current[key][subFieldData?.key]
                ? total + 1
                : total;
        }, 0);
        return sum;
    }
    return 2;
};
