import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { createHeaders } from '../functions/functions';

import TableRow from './TableRow';
const TableContent = ({
  tableColumns,
  formData,
  setFormData,
  columnsConfig,
}) => {
  const columnsList = useMemo(() => {
    return createHeaders(tableColumns, columnsConfig);
  }, [tableColumns, columnsConfig]);

  return (
    <tbody>
      {formData.length > 0 &&
        formData.map((row) => {
          return (
            <TableRow
              key={row.id}
              row={row}
              setFormData={setFormData}
              columnsList={columnsList}
              columnsConfig={columnsConfig}
            />
          );
        })}
    </tbody>
  );
};

export default TableContent;
