import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  addNewFormRow,
  createHeaders,
  getColumnProperties,
  summaryCounter,
} from '../functions/functions';
import SummaryRow from './sub-parts/SummaryRow';
import CancellationRow from './sub-parts/CancellationRow';
import TableHeaders from './sub-parts/TableHeaders';

const FormTable = ({
  columns,
  minCellWidth,
  formData,
  setFormData,
  columnsConfig,
  tableContent,
  cancelledItems,
  setCancelledItems,
  orderData,
}) => {
  const [tableHeight, setTableHeight] = useState('auto');
  const [activeIndex, setActiveIndex] = useState(null);
  const tableElement = useRef(null);

  const memoColumnsList = useMemo(() => {
    return createHeaders(columns, columnsConfig);
  }, [columns, columnsConfig]);

  const columnsList = memoColumnsList.map((column) => ({
    ...column,
    ref: useRef(),
  }));
  useEffect(() => {
    setTableHeight(tableElement.current.offsetHeight - 20);
  }, []);

  const mouseDown = (index) => {
    setActiveIndex(index);
  };

  const mouseMove = useCallback(
    (e) => {
      const gridColumns = columnsList.map((col, i) => {
        if (i === activeIndex) {
          const width =
            e.clientX -
            col.ref.current.offsetLeft -
            81 +
            tableElement.current.scrollLeft;
          if (width >= minCellWidth) {
            return `${width}px`;
          }
        }
        return `${col.ref.current.offsetWidth}px`;
      });

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(
        ' '
      )}`;
    },
    [activeIndex, columnsList, minCellWidth]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  useEffect(() => {
    const gridColumns = columnsList.map((col, i) => {
      return col.width;
    });
    tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
  }, []);

  let stickyCount = -1;

  return (
    <div className="table-container">
      <div className="table-wrapper">
        <table className="resizeable-table" ref={tableElement}>
          <TableHeaders
            columnsList={columnsList}
            activeIndex={activeIndex}
            mouseDown={mouseDown}
          />
          {/* tabel content */}
          {columnsList?.length > 0 && formData?.length > 0 && tableContent}
          {/* new row add button */}
          <tbody>
            <tr>
              <td style={{ gridColumn: 'span ' + columnsList?.length }}>
                <button
                  className="btn btn-success btn-sm "
                  style={{ width: 'auto', padding: '7px 10px' }}
                  onClick={() => {
                    addNewFormRow(setFormData, columnsList);
                  }}
                >
                  <span className="fa fa-plus"> </span>
                </button>
              </td>
            </tr>
            {/* cancellation-items-row */}
            <CancellationRow
              columnsList={columnsList}
              formData={formData}
              columnsConfig={columnsConfig}
              cancelledItems={cancelledItems}
              setCancelledItems={setCancelledItems}
              orderData={orderData}
            />
            {/* summary row */}
            <SummaryRow
              columnsList={columnsList}
              formData={formData}
              columnsConfig={columnsConfig}
            />
            <tr>
              <td style={{ gridColumn: 'span ' + columnsList?.length }}>
                <div style={{ height: '230px' }}></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FormTable;
