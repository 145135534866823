import { typChoices } from '../static/choiceOptions';
import {
  genderChoices,
  b2bChoices,
  countryChoices,
  passportChoices,
} from '../static/choiceOptions';
export const columnProperties = async (client, overrideConfig) => {
  const countriesList = await countryChoices(client);

  let columnData = [
    {
      key: 'action',
      type: 'buttons',
      width: '0.25fr',
      minWidth: '6px',
      sortOrder: 1,
      hideFooterCount: true,
    },
    {
      key: 'type',
      type: 'choice',
      choices: typChoices,
      required: true,
      sortOrder: 10,
      isMulti: false,
      width: '1fr',
      minWidth: '116px',
      defaultValue: 'participant',

    },
    {
      key: 'b2bGroup',
      label: 'ZiTyp',
      type: 'choice',
      choices: [],
      width: '0.75fr',
      minWidth: '86px',
      sortOrder: 20,
      isMulti: false,
    },
    {
      key: 'roomIndex',
      label: 'ZiNr',
      type: 'input',
      subType: 'number',
      required: false,
      width: '1fr',
      minWidth: '80px',
      sortOrder: 30,
      defaultValue: 1,
    },
    {
      key: 'twinRoom',
      label: 'Twin Zimmer',
      type: 'input',
      subType: 'checkbox',
      required: false,
      width: '0.55fr',
      minWidth: '65px',
      sortOrder: 31,
    },
    {
      key: 'salutation',
      type: 'choice',
      choices: genderChoices,
      width: '0.65fr',
      minWidth: '80px',
      sortOrder: 40,
      isMulti: false,
      defaultValue: 'male',
    },
    {
      key: 'firstName',
      type: 'input',
      subType: 'text',
      required: true,
      width: '1fr',
      minWidth: '136px',
      sortOrder: 50,
      sticky: true,
    },
    {
      key: 'lastName',
      type: 'input',
      subType: 'text',
      required: true,
      width: '1fr',
      sticky: true,
      minWidth: '136px',
      sortOrder: 60,
    },
    {
      key: 'bookingRemark',
      type: 'input',
      subType: 'text',
      width: '1fr',
      minWidth: '136px',
      sortOrder: 70,
    },
    {
      key: 'birthdate',
      type: 'input',
      subType: 'date',
      width: '1fr',
      minWidth: '136px',
      sortOrder: 80,
      required: false,
    },
    {
      key: 'nationality',
      type: 'choice',
      choices: countriesList,
      required: false,
      width: '1fr',
      minWidth: '136px',
      sortOrder: 90,
      isMulti: false,
    },
    {
      key: 'combinedOrderItems',
      width: '1fr',
      minWidth: '264px',
      type: 'input',
      subType: 'choice',
      sortOrder: 109,
    },

    {
      key: 'orderItems',
      width: '1fr',
      minWidth: '136px',
      type: 'input',
      subType: 'checkbox',
      sortOrder: 109,
    },

    {
      key: 'passport',
      type: 'nested',
      width: '1fr',
      minWidth: '136px',
      sortOrder: 9999999,
    //   showIf: [{ key: 'type', condition: 'or', value: 'driver' }], // multiple conditions can be added as new object in array
      subFields: [
        {
          key: 'type',
          type: 'choice',
          choices: await passportChoices(client),
          label: 'DokTyp',
        },
        {
          key: 'countryOfIssue',
          type: 'choice',
          choices: countriesList,
          label: 'AusstellLand',
        },
        {
          key: 'passportNumber',
          label: 'DokNr.',
          type: 'input',
          subType: 'text',
          choices: await passportChoices(client),
        },
        {
          key: 'dateOfIssue',
          label: 'AusstellDat.',
          type: 'input',
          subType: 'date',
        },
        {
          key: 'placeOfIssue',
          label: 'Gültig bis.',
          type: 'input',
          subType: 'text',
          choices: countriesList,
        },
        {
          key: 'dateOfExpiry',
          label: 'AusstellOrt.',
          type: 'input',
          subType: 'date',
        },
      ],
    },
    { key: 'notFound', width: '1fr', minWidth: '136px', sortOrder: 9999999999 },
  ];
  //  this will override default config
  if (overrideConfig?.length > 0) {
    columnData = columnData.map((column) => {
      const newColumn = overrideConfig.find(
        (config) => config.key === column.key
      );
      return newColumn
        ? {
            ...column,
            ...newColumn,
            showIf: [...(column.showIf || []), ...(newColumn.showIf || [])],
          }
        : { ...column };
    });
  }
  return columnData;
};
