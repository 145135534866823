import React, { useState, useEffect, useRef } from 'react';
import {
  getParticipantsData,
  makeFormDataFromDefaultData,
  makeRequestData,
  makeNewFormEmptyRows,
  postToServer,
  makeHeadersFromDefaultData,
  cancelledOrderItemsData,
} from '../functions/functions';
import FormTable from './FormTable';
import { columnProperties } from './ColumnProperties';
import Skeleton from '@yisheng90/react-loading';
import { useImmer } from 'use-immer';
import WorkloadButtons from './WorkloadButtons';
import TableContent from './TableContent';

const ParticipantsRenderer = ({ services, order }) => {
  const [tableColumns, setTableColumns] = useState([
    { key: 'action', label: '' },
  ]);
  const [orderData, setOrderData] = useState();
  const [formData, setFormData] = useState([]);
  const [cancelledItems, setCancelledItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columnsConfig, setColumnsConfig] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const defaultData = await getParticipantsData(services.client, order.id);
      setOrderData(defaultData);

      const roomChoices = defaultData?.roomConfig.map((room) => ({
        label: room.title,
        value: room.groupId,
        roomAmount: room.roomAmount,
        rowsPerRoom: room.rowsPerRoom,
      }));

      const onRoomSelectionChange = (newVal, rowId) => {
        setFormData((data) => {
          return data.map((item) => {
            if (item.id === rowId) {
              let b2bItemServices = [];
              const isDriver = item.type === 'driver';
              const orderItemsWithDriverIsParticipant = defaultData.orderItemHeads
                ?.filter((head) => head.driverIsParticipant == true)
                ?.map((i) => i?.itemId);

              defaultData?.orderItemHeads.forEach((oItem) => {
                const isServiceExist = defaultData.roomConfig?.some((rItem) =>
                  rItem?.associatedOrderItems?.includes(oItem?.itemId)
                );
                if (
                  !isServiceExist &&
                  (orderItemsWithDriverIsParticipant?.includes(oItem?.itemId) ||
                    !isDriver)
                ) {
                  b2bItemServices.push({ id: oItem?.itemId });
                }
              });
              const roomItems = defaultData?.roomConfig
                .find((item) => item.groupId == newVal)
                .associatedOrderItems?.map((item) => {
                  return orderItemsWithDriverIsParticipant.includes(item) ||
                    !isDriver
                    ? { id: item }
                    : undefined;
                })
                ?.filter((i) => i);
              return {
                ...item,
                orderItems:
                  roomItems?.length > 0
                    ? [...roomItems, ...b2bItemServices]
                    : [...b2bItemServices],
              };
            } else {
              return item;
            }
          });
        });
      };

      const onTwinRoomChange = (newVal, rowId, setData) => {
        setData((currentData) => {
          const rowInfo = currentData.find((data) => data.id == rowId);
          const otherRowWithSameRoom = currentData.find(
            (data) =>
              data?.b2bGroup == rowInfo?.b2bGroup &&
              data.roomIndex === rowInfo.roomIndex &&
              data.id !== rowId
          )?.id;
          if (otherRowWithSameRoom) {
            const newFormData = currentData?.map((row) => {
              return row.id === otherRowWithSameRoom || row.id === rowId
                ? { ...row, twinRoom: newVal }
                : row;
            });
            return newFormData;
          } else {
            const newFormData = currentData?.map((row) => {
              return row.id === rowId ? { ...row, twinRoom: newVal } : row;
            });
            return newFormData;
          }
        });
      };

      const filteredObject = (inputObject, idsToRemove) => {
        return inputObject
          ? Object.fromEntries(
              Object.entries(inputObject).filter(
                ([key, value]) => !idsToRemove.includes(value)
              )
            )
          : {};
      };

      const onTypeChange = (newVal, rowId, setData) => {
        if (newVal == 'driver') {
          setData((currentData) => {
            const orderItemsWithDriverIsParticipant = defaultData.orderItemHeads
              ?.filter((head) => head.driverIsParticipant == true)
              ?.map((i) => i.itemId);
            const combinedOrderItemsWithDriverIsParticipant = defaultData.combinedOrderItemHeads
              ?.filter((head) => head.driverIsParticipant == true)
              ?.flatMap((i) => i?.values?.map((v) => v.itemId));
            const newFormData = currentData?.map((row) => {
              return row.id === rowId
                ? {
                    ...row,
                    orderItems: row?.orderItems?.filter((i) =>
                      orderItemsWithDriverIsParticipant.includes(i.id)
                    ),
                    combinedOrderItems: filteredObject(
                      row?.combinedOrderItems,
                      combinedOrderItemsWithDriverIsParticipant
                    ),
                  }
                : row;
            });
            return newFormData;
          });
        }
      };

      const doubleRoomId = roomChoices?.find((room) => room.rowsPerRoom == 2)
        ?.value;
      const columnPropertiesData = await columnProperties(services.client, [
        {
          key: 'b2bGroup',
          choices: roomChoices,
          onPostChange: onRoomSelectionChange,
        },
        {
          key: 'type',
          onPostChange: onTypeChange,
        },
        {
          key: 'twinRoom',
          onPostChange: onTwinRoomChange,
          showIf: [
            {
              key: 'b2bGroup',
              condition: 'or',
              value: doubleRoomId,
            },
          ],
        },
        {
          key: 'passport',
          showIf: [
            {
              field: order?.passportsRequired,
              condition: 'or',
              value: true,
            },
          ],
        },
      ]);
      setColumnsConfig(columnPropertiesData);

      const tableColumnHeaders = makeHeadersFromDefaultData(
        defaultData,
        tableColumns,
        services,
        columnPropertiesData,
        order
      );
      setTableColumns(tableColumnHeaders);

      if (defaultData?.participants.length > 0) {
        const defaultFormData = makeFormDataFromDefaultData(
          defaultData,
          tableColumnHeaders
        );
        const cancelledOrdersItemsData = cancelledOrderItemsData(defaultData);
        if (defaultFormData) {
          setFormData(defaultFormData);
          if (cancelledOrdersItemsData.length > 0) {
            setCancelledItems(cancelledOrdersItemsData);
          }
          setIsNew(false);
        }
      } else {
        const roomConfig = defaultData?.roomConfig;
        setFormData(
          makeNewFormEmptyRows(
            tableColumnHeaders,
            columnPropertiesData,
            roomConfig
          )
        );
        setIsNew(true);
      }
      setLoading(false);
    };
    getData();
  }, []);

  const submitForm = async () => {
    setFormSubmitting(true);
    const [data, notPostable, haveErrors] = makeRequestData(
      formData,
      orderData,
      tableColumns,
      cancelledItems
    );
    console.log(data);
    haveErrors.forEach(({ error }) => {
      services.flash.error(error);
    });
    if (data.rows.length > 0) {
      try {
        const response = await postToServer(data, services.client);
        if (response.ok) {
          if (!isNew) {
            setOrderData((orderData) => {
              return {
                ...orderData,
                participants: response?.rows || [],
              };
            });
          }
          services.flash.success('Erfolgreich gespeichert.');
          services.ea.publish('sio_form_post_submit', {
            config: { modelId: 'tourism-order/b2b-offer-participant' },
          });
        } else {
          services.flash.error('Fehler bei der Übermittlung des Formulars');
        }
      } catch (e) {
        services.flash.error('Fehler bei der Übermittlung des Formulars');
      }
    }

    setFormSubmitting(false);
  };

  return (
    <div className="participant-list" style={{ opacity: loading ? 0.5 : 1 }}>
      {!loading ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <WorkloadButtons order={order} services={services} />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '12px',
                gap: '1rem',
              }}
            >
              <button
                className="btn btn-default no-icon btn-sm sio-action"
                onClick={async () => {
                  setFormData(
                    makeNewFormEmptyRows(
                      tableColumns,
                      columnsConfig,
                      orderData?.roomConfig
                    )
                  );
                  setIsNew(true);
                }}
              >
                Rücksetzen
              </button>
              <button
                className="btn btn-primary form-submit  btn-sm sio-action"
                onClick={submitForm}
              >
                {formSubmitting ? (
                  <div style={{ display: 'flex', gap: '12px' }}>
                    <div
                      role="progressbar"
                      className="rs-loader-wrapper rs-loader-speed-normal"
                    >
                      <div className="rs-loader">
                        <span className="rs-loader-spin"></span>
                      </div>
                    </div>
                    <div>Speichern</div>
                  </div>
                ) : (
                  'Speichern'
                )}
              </button>
            </div>
          </div>
          <FormTable
            columns={tableColumns}
            columnsConfig={columnsConfig}
            setFormData={setFormData}
            minCellWidth={120}
            services={services}
            formData={formData}
            setCancelledItems={setCancelledItems}
            cancelledItems={cancelledItems}
            orderData={orderData}
            tableContent={
              <TableContent
                tableColumns={tableColumns}
                formData={formData}
                setFormData={setFormData}
                columnsConfig={columnsConfig}
              />
            }
          />
        </>
      ) : (
        <div style={{ background: 'white', padding: '2rem' }}>
          <Skeleton width={'100%'} height={'3rem'} rows={8} />
          <Skeleton width={'80%'} height={'3rem'} rows={6} />
          <Skeleton width={'55%'} height={'3rem'} rows={2} />
        </div>
      )}
    </div>
  );
};

export default ParticipantsRenderer;
