// b2b-participant-type
export const typChoices = [
    {
        label: 'Teilnehmer*in',
        value: 'participant',
    },
    {
        label: 'Fahrer*in',
        value: 'driver',
    },
    {
        label: 'Reiseleiter*in',
        value: 'guide',
    },
];

// salutation
export const genderChoices = [
    {
        label: 'Frau',
        value: 'female',
    },
    {
        label: 'Herr',
        value: 'male',
    },
    {
        label: 'divers',
        value: 'unspecified',
    },
];

export const countryChoices = async (client) => {
    return await client.get('form/choice/country');
};
export const passportChoices = async (client) => {
    return await client.get('form/choice/passport-type');
};
